import React, { useState } from 'react';
import { StyledTabHeader, StyledTabs } from "../../../../reusable.styles"

import CardsSection from '../../../sections/CardsSection/CardsSection';
import { StyledSection } from "./HomepageTabbedCardsSection.styles"

const tabs = [
  {
    heading: "Ways to order",
    cards:[
      { 
        title: "Individual Meals",
        description: "Employees can order\u2014in the office or at home\u2014with budgets and rules to control spend.",
        link: "/individual-meals/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-IndividualMeals.jpg",
        linkText: "Learn more"
      },
      { 
        title: "Group Ordering",
        description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
        link: "/group-orders/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
        linkText: "Learn more"
      },
      { 
        title: "Catering",
        description: "Feed your next team meeting or event with catering delivered as platters or individually boxed meals.",
        link: "/catering/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1652231061/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Homepage/EXPORTS-CARD-Catering.jpg",
        linkText: "Learn more"
      }
    ]
  },
  {
    heading: "Ordering occasions",
    cards:[
      { 
        title: "Hybrid Teams",
        description: "Keep your hungry teams happy, whether they're in the office, at home or both.",
        link: "/hybrid-teams/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
        linkText: "Learn more"
      },
      { 
        title: "Digital Events",
        description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
        link: "/meals-for-digital-events/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-DigitalEvents.jpg",
        linkText: "Learn more"
      },
      { 
        title: "Remote Employees",
        description: "Show your remote team you care with customizable meal plans from Grubhub.",
        link: "/meals-for-remote-employees/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797391/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-RemoteEmployees.jpg",
        linkText: "Learn more"
      },
      { 
        title: "Corporate Perks",
        description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
        link: "/perks/",
        imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
        linkText: "Learn more"
      }
    ]
  }
]

const HomepageTabbedCardsSection = ({cards=[...tabs], backgroundColorTabs = '#F9F9F9', ...restProps}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <StyledSection backgroundColor={backgroundColorTabs || "#F9F9F9" } >
      <StyledTabs value={value} onChange={handleChange} >
        {/* Render the tab titles based on the tabs array set above */}
        {tabs.map((tab, i) => (
            <StyledTabHeader label={tab.heading} key={tab.heading} />
        ))}
      </StyledTabs>
      
      {/* Render the tab panels */}
      {cards.map((tab, i) => (
        <TabPanel value={value} index={i} key={tab.heading} className="row">
          {/* Render the cards for each panel */}
          <CardsSection cards={tab.cards} cardsPerRow={3} chunkCards backgroundColor="transparent" sectionPadding="0px" isSliderOnMobile/>
        </TabPanel>
      ))}

    </StyledSection>
  )
  
};

export default HomepageTabbedCardsSection;


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}
