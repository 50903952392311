import styled from 'styled-components';
import SectionWrapper from "../../../sections/SectionWrapper"
import { queries } from "../../../../breakpoints"

export const StyledSection = styled(SectionWrapper)`
    .PrivateTabIndicator-colorSecondary-3, .MuiTabs-indicator {
        background-color: var(--color-primary);
        z-index: 2;
    }
    // remove the max-width on the container for sizes under break_lg so the slider extends to the edge and off the screen
    @media(${queries.max_break_lg}){
        .container{
            max-width: unset;
        }
    }
`;