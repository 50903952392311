import styled, { css } from 'styled-components';

import { queries } from "../../../breakpoints"

export const StyledGeneralHeader = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    ${props => !props.hasChildren && props.type !== "half-image" && props.type !== "full-image" && css`padding: 80px 0px;` }
    ${props => !props.hasChildren && props.type === "full-image" && css`
          padding: 25px 0px;
          ${ImageContainer} {
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            @media (${queries.min_break_md}) {
              min-width: 438px;
            }
          }
    
          @media (${queries.max_break_md}) {
          }
    `}
`;
export const ContentContainer = styled.div``;

export const Content = styled.div`
    max-width: 900px;
    margin:0 auto;

`;

export const Preheader = styled.h3`
    font-weight: bold;
    text-transform: uppercase;
    color: #aeaeae;
    letter-spacing: 4.14px;
    font-size: clamp(var(--fontSize-1), 1.15vw, 3rem);
    margin: 0;
`;

export const Headline = styled.h1`
    font-weight: bold;  
    max-width: 650px;
    margin: 0px auto 20px;
    font-size: clamp(var(--fontSize-8), 3.5vw, 7rem);
`;

export const Subheadline = styled.p`
    margin-bottom: 30px;
    font-size: var(--fontSize-2);
`;

export const ImageContainer = styled.div`
    display:none;
`;
export const Image = styled.div`
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;

  ${props => props.type !== "full-image" &&
          css`
            clip-path: circle(70% at 75% 50%);
            @media (max-width: 1650px) {
              clip-path: circle(70% at 87% 50%);
            }
            @media (max-width: 1300px) {
              clip-path: circle(70% at 96% 50%);
            }
            @media (max-width: 1100px) {
              clip-path: circle(70% at 107% 50%);
            }
            @media (max-width: 992px) {
              clip-path: circle(70% at 115% 50%);
            }
            @media (${queries.max_break_md}) {
              clip-path: circle(70% at 50% 90%);
            }
    `} 
`;

export const ImageHolder = styled.img`
    position: relative;
    height: 100%;
    width: 100%;
   ${ props => props.type !== "full-image" &&
    css`
      clip-path: circle(70% at 70% 50%);
      opacity: 0;
      z-index: -1;
    `}    
`;

export const Container = styled.div`
    min-height: 300px;
    align-items: center;
    justify-content: center;
    display: flex;
    ${props => props.type === "half-image" &&
        css`
            display: grid;
            grid-template-areas: "content image";
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            height: 100%;
            width:100%;
            ${ContentContainer}{
                grid-area: content;
                text-align: left;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
            }
            ${Content}{
                max-width: 650px;
                padding: 50px 25px;
                @media (${queries.max_break_md}){
                    max-width: 100%;
                    text-align: center;
                    padding: 40px 25px;
                }
            }
            ${Preheader}{
                font-weight: bold;
                text-transform: uppercase;
                color: #aeaeae;
                letter-spacing: 4.14px;
                margin: 0;
                @media (${queries.max_break_md}){
                    font-size: var(--fontSize-0);
                }
            }
            ${Headline}{
                font-weight: bold;
                
                @media (${queries.max_break_md}){
                    font-size: var(--fontSize-7);
                }
            }
            ${Subheadline}{
                margin-bottom: 30px;
                font-size: var(--fontSize-2);
            }
            ${ImageContainer}{
                display: block;
                grid-area: image;
                height: 100%;
                position: relative;
                @media (${queries.min_break_md}){
                    min-width:438px;
                }
            }
            @media (${queries.max_break_md}){
                grid-template-areas: "content" "image";
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
            }
        `
    }
    ${props => props.type === "full-image" &&
            css`
              display: flex;
              justify-content: flex-start;
              align-self: flex-start;
              align-content: flex-start;
              height: 100%;
              width:100%;
            ${ContentContainer}{
                text-align: left;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: column; 
                z-index: 2;
            }
            ${Content}{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                max-width: 650px;
                padding: 50px 25px;
                @media (${queries.max_break_md}){
                    max-width: 100%;
                    text-align: center;
                    padding: 40px 25px;
                }
            }
            ${Preheader}{
                font-weight: bold;
                text-transform: uppercase;
                color: #aeaeae;
                letter-spacing: 4.14px;
                margin: 0;
                @media (${queries.max_break_md}){
                    font-size: var(--fontSize-0);
                }
            }
            ${Headline}{
                font-weight: bold;
                margin: 0;
                @media (${queries.max_break_md}){
                    font-size: var(--fontSize-7);
                }
            }
            ${Subheadline}{
                margin-bottom: 30px;
                font-size: var(--fontSize-2);
            }
        `
    }
`;

